/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../geely/src/style/abstract/variables';
@import '../../../../geely/src/style/abstract/media';
@import '../../../../geely/src/style/abstract/button';
@import '../../../../geely/src/style/abstract/loader';
@import '../../../../geely/src/style/abstract/icons';
@import '../../../../geely/src/style/abstract/parts';
@import '../../../../geely/src/style/abstract/variables.override';
@import '../../../../geely/src/style/abstract/button.override';
@import '../../../../geely/src/style/abstract/media.override';
@import '../../../../geely/src/style/abstract/parts.override';
@import '../../../../geely/src/style/abstract/icons.override';

.CheckoutPayments {
    font-weight: 700;
    width: 100%;

    .Checkout {
        &-Heading {
            font-size: 16px;
            line-height: 24px;
        }
    }

    &-Methods {
        display: flex;
        flex-direction: column;

        @include desktop {
            width: 100%;
            flex-direction: row;
        }
    }
}
